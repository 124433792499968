import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Icons
import Logo from "./logo.png";
import Style from "./ComingSoon.module.css";
import Countdown from "./Countdown";

const ComingSoon = () => {
  // const [timeLeft, setTimeLeft] = useState(7 * 24 * 60 * 60); // in seconds
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTimeLeft((timeLeft) => timeLeft - 1);
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);
  // const daysLeft = Math.floor(timeLeft / (24 * 60 * 60));
  // const hoursLeft = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
  // const minutesLeft = Math.floor((timeLeft % (60 * 60)) / 60);
  // const secondsLeft = timeLeft % 60;
  return (
    <div className={Style.container}>
      <img src={Logo} alt="logo" className={Style.logo} />
      <h1 className={Style.title}>welcome Zenit World</h1>
      <div className={Style.counterContainer}>
        {" "}
        <Countdown timerDate={"2023-04-19T13:00:00.609Z"}></Countdown>
        {/* {daysLeft} days : {hoursLeft} hours : {minutesLeft} minute :{" "}
        {secondsLeft} seconds */}
      </div>
      <a
        href="https://white-paper.zenit.world/what-is-zenit/what-why-and-how"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "white" }}
      >
        VISIT OUR WHITEPAPER
      </a>
    </div>
  );
};

export default ComingSoon;
