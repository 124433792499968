import React from "react";
import ReactDOM from "react-dom/client";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import "./test.css";

// Layouts
import MainLayout from "./Layouts/MainLayout";

// Views
// import Homepage from "./Views/Homepage/Homepage";
// import Ecosystem from "./Views/Ecosystem/Ecosystem";
// import WhatIsZenit from "./Views/WhatIsZenit/WhatIsZenit";
// import Security from "./Views/Security/Security";
// import Fundamentals from "./Views/Fundamental/Fundamentals";
// import Contact from "./Views/Contact/Contact";
// import WhyUs from "./Views/WhyUs/WhyUs";
// import Roadmap from './Views/Roadmap/Roadmap';
// import AboutUs from './Views/AboutUs/AboutUs';
// import Team from './Views/Team/Team';
// import Launchpool from './Views/Launchpool/Launchpool';
// import Blog from './Views/Blog/Blog';
// import SingleBlog from './Views/SingleBlog/SingleBlog';
// import Career from './Views/Career/Career';
// import CareerSingle from './Views/CareerSingle/CareerSingle';
import ComingSoon from "./Views/ComingSoon/ComingSoon";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { generateApiClient, ApiProvider } from "@hybris-software/use-query";

const apiClient = generateApiClient({
  baseUrl: "https://api.zenit.world/api/v1/",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApiProvider apiClient={apiClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ComingSoon />}>
            {/* <Route path="" element={<Homepage />} />
            <Route path="ecosystem" element={<Ecosystem />} />
            <Route path="what-is-zen" element={<WhatIsZenit />} /> */}
            {/* <Route path='strategy-pool' element={<Launchpool />} /> */}

            {/* <Route path="security" element={<Security />} /> */}
            {/* <Route path="fundamental" element={<Fundamentals />} /> */}

            {/* <Route path='blog' element={<Blog />} /> */}
            {/* <Route path='blog/:id' element={<SingleBlog />} /> */}

            {/* <Route path='about-us' element={<AboutUs />} /> */}
            {/* <Route path='roadmap' element={<Roadmap />} /> */}
            {/* <Route path='team' element={<Team />} /> */}

            {/* <Route path="why-us" element={<WhyUs />} /> */}

            {/* <Route path='career' element={<Career/>} /> */}
            {/* <Route path='career/:id' element={<CareerSingle/>} /> */}

            {/* <Route path="contact" element={<Contact />} /> */}

            {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}

            {/* Add main route here */}
          </Route>
        </Routes>
      </BrowserRouter>
    </ApiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
